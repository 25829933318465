<template>
  <div class="exception-body notfound">
    <div class="exception-panel">
      <h1>404</h1>
      <h3>not found</h3>
      <p>The page that you are looking for does not exist</p>
      <router-link to="/">
        <Button label="Go back to home" type="button"></Button>
      </router-link>
    </div>
    <div class="exception-footer">
      <img
        class="exception-logo"
        src="../assets/sass/layout/images/logo-light.png"
      />
      <span class="app-name"><strong>Home Expenses</strong></span>
    </div>
  </div>
</template>

<script>
import { defineComponent } from "vue";

export default defineComponent({});
</script>

<style lang="scss" scoped>
span {
  margin-left: 10px;
}
</style>
